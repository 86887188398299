/**=====================
     42. Timeline CSS Start
==========================**/
.activity {
	.media {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.gradient-round {
			position: relative;
			width: 53px;
			height: 53px;
			background-image: $gradient-primary;
			border-radius: 100%;
			top: -18px;

			svg {
				position: absolute;
				width: 18px;
				height: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				top: 18px;
				right: 0;
				margin: 0 auto;

				path,
				line,
				circle {
					color: $white;
				}
			}

			&.gradient-line-1 {
				&:after {
					position: absolute;
					content: '';
					background-color: #e9e9e9;
					width: 2px;
					height: 62px;
					bottom: -77px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}

			&.small-line {
				&:after {
					position: absolute;
					content: '';
					background-color: #e9e9e9;
					width: 2px;
					height: 19px;
					bottom: -34px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}

			&.medium-line {
				&:after {
					position: absolute;
					content: '';
					background-color: #e9e9e9;
					width: 2px;
					height: 53px;
					bottom: -68px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
		}

		.media-body {
			h6 {
				font-weight: 500;
				margin-bottom: 30px;

				span {
					font-weight: normal;
					color: $light-text;
				}
			}

			p {
				width: 80%;
			}
		}
	}
}

.timeline-small {
	.media {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.timeline-round {
			position: relative;
			width: 53px;
			height: 53px;
			border-radius: 100%;
			border-radius: 15px;
			top: -18px;

			svg {
				position: absolute;
				width: 18px;
				height: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				top: 18px;
				right: 0;
				margin: 0 auto;

				path,
				line,
				circle {
					color: $white;
				}
			}

			&.timeline-line-1 {
				&:after {
					position: absolute;
					content: '';
					background-color: #e9e9e9;
					width: 2px;
					height: 62px;
					bottom: -88px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}

			&.small-line {
				&:after {
					position: absolute;
					content: '';
					background-color: #e9e9e9;
					width: 2px;
					height: 19px;
					bottom: -34px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}

			&.medium-line {
				&:after {
					position: absolute;
					content: '';
					background-color: #e9e9e9;
					width: 2px;
					height: 53px;
					bottom: -68px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
		}

		.media-body {
			h6 {
				margin-bottom: 30px;

				span {
					font-weight: normal;
					color: $light-text;
				}
			}

			p {
				width: 80%;
			}
		}
	}
}

.cd-container {
	width: 90%;
	max-width: 1170px;
	margin: 0 auto;
}

.cd-container::after {
	content: '';
	display: table;
	clear: both;
}

@media only screen and (min-width: 1170px) {

	#cd-timeline::before {
		left: 50% !important;
		margin-left: -2px;
	}

	.cd-timeline-block {
		margin: 4em 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:nth-child(even) {
			.cd-timeline-content {
				float: right;

				.cd-date {
					left: auto;
					right: 122%;
					text-align: right;
				}
			}

			.cd-timeline-content::before {
				top: 24px;
				left: auto;
				right: 100%;
				border-color: transparent;
				border-right-color: $light-color;
			}
		}
	}

	.cd-timeline-img {
		width: 60px;
		height: 60px;
		left: 50%;
		margin-left: -30px;

		>i {
			font-size: 18px;
			top: 50%;
			left: 50%;
			padding: 3px;
		}
	}

	.cssanimations {
		.cd-timeline-img {
			&.is-hidden {
				visibility: hidden;
			}

			&.bounce-in {
				visibility: visible;
				animation: cd-bounce-1 0.6s;
			}
		}

		.cd-timeline-content {
			&.is-hidden {
				visibility: hidden;
			}

			&.bounce-in {
				visibility: visible;
				animation: cd-bounce-2 0.6s;
			}
		}

		.cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
			animation: cd-bounce-2-inverse 0.6s;
		}
	}

	.cd-timeline-content {
		margin-left: 0;
		padding: 1.6em;
		width: 45%;

		.cd-date {
			position: absolute;
			width: 100%;
			left: 122%;
			top: 6px;
			font-size: 16px;
		}
	}

	.cd-timeline-content::before {
		top: 24px;
		left: 100%;
		border-color: transparent;
		border-left-color: $white;
	}
}

#cd-timeline {
	position: relative;
	padding: 2em 0;
}

#cd-timeline::before {
	content: '';
	position: absolute;
	top: 0;
	font-size: 1.5rem;
	height: 100%;
	width: 2px;
	background: $light-color;
	left: -15px;
}

.cd-timeline-block {
	position: relative;
	margin: 30px 0;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.cd-timeline-img {
	width: 60px;
	height: 60px;
	left: 50%;
	margin-left: -30px;
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	position: absolute;
	top: 0;
	border-radius: 4px;

	i {
		display: block;
		position: relative;
		left: 45%;
		top: 44%;
		margin-left: -12px;
		margin-top: -12px;
		font-size: 27px;
		color: $white;
	}
}

@-webkit-keyframes cd-bounce-1 {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}
}

@-moz-keyframes cd-bounce-1 {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}
}

@keyframes cd-bounce-1 {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}

	60% {
		opacity: 1;
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

.cd-timeline-content {
	position: relative;
	background: $light;
	border-radius: 4px;
	padding: 30px;
	box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);

	p {
		padding-top: 9px;
	}

	h4 {
		margin-bottom: 0;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	audio {
		width: 100%;
	}

	.cd-date {
		font-size: 13px;
		display: inline-block;
		float: left;
		padding: .8em 0 0;
		opacity: .7;
	}
}

.cd-timeline-content::before {
	content: '';
	position: absolute;
	top: 16px;
	right: 100%;
	height: 0;
	width: 0;
	border: 7px solid transparent;
	border-left: 7px solid $light-color;
}

@media only screen and (min-width: 768px) {
	.cd-timeline-content {
		.cd-date {
			font-size: 14px;
			padding-bottom: 0;
		}
	}
}

@-webkit-keyframes cd-bounce-2 {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}
}

@-moz-keyframes cd-bounce-2 {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	100% {}
}

@keyframes cd-bounce-2 {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}

	60% {
		opacity: 1;
		transform: translateX(20px);
	}

	100% {
		transform: translateX(0);
	}
}

@-webkit-keyframes cd-bounce-2-inverse {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}
}

@-moz-keyframes cd-bounce-2-inverse {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	100% {}
}

@keyframes cd-bounce-2-inverse {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}

	60% {
		opacity: 1;
		transform: translateX(-20px);
	}

	100% {
		transform: translateX(0);
	}
}

@media only screen and (max-width: 1169px) {
	.cd-timeline-block {
		.cd-timeline-img {
			left: -2px;
			height: 30px;
			width: 30px;
			margin-left: -30px;
			top: 14px;
			z-index: 7;

			>i {
				font-size: 15px;
				top: 16px;
				left: 17px;
				padding: 3px;
			}
		}
	}

	.landing-main {
		#cd-timeline::before {
			left: -39px;
		}

		.cd-timeline-block {
			.cd-timeline-img {
				left: -34px;
				height: 50px;
				width: 50px;
				top: 1px;
				z-index: 8;

				>i {
					font-size: 23px;
					top: 21px;
					left: 7px;
					padding: 3px;
				}
			}
		}
	}
}

@media only screen and (max-width: 576px) {
	.cd-timeline-content {
		padding: 15px;

		h4 {
			font-size: 18px;
		}
	}
}

/**=====================
     42. Timeline  CSS Ends
==========================**/