.img-cropper {
  .btn {
    padding: 6px 15px;
  }

  label.btn {
    margin-bottom: 0;
  }

  .d-flex > .btn {
    flex: 1;
  }

  .carbonads {
    border-radius: .25rem;
    border: 1px solid #ccc;
    font-size: .875rem;
    overflow: hidden;
    padding: 1rem;
  }

  .carbon-wrap {
    overflow: hidden;
  }

  .carbon-img {
    clear: left;
    display: block;
    float: left;
  }

  .carbon-text,
  .carbon-poweredby {
    display: block;
    margin-left: 140px;
  }

  .carbon-text,
  .carbon-text:hover,
  .carbon-text:focus {
    color: #fff;
    text-decoration: none;
  }

  .carbon-poweredby,
  .carbon-poweredby:hover,
  .carbon-poweredby:focus {
    color: #ddd;
    text-decoration: none;
  }


    .carbonads {
      float: right;
      margin-bottom: -1rem;
      margin-top: -1rem;
      max-width: 360px;
    }


  .heart {
    color: #ddd;
    display: block;
    height: 2rem;
    line-height: 2rem;
    margin-bottom: 0;
    margin-top: 1rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .heart:hover {
    color: #ff4136;
  }

  .heart::before {
    border-top: 1px solid #eee;
    content: " ";
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .heart::after {
    background-color: #fff;
    content: "♥";
    padding-left: .5rem;
    padding-right: .5rem;
    position: relative;
    z-index: 1;
  }

  .img-container,
  .img-preview {
    background-color: #f7f7f7;
    text-align: center;
    width: 100%;
  }

  .img-container {
    margin-bottom: 1rem;
    max-height: 497px;
    min-height: 200px;
    .cropper-bg{
      width: 100%!important;
    }
  }

    .img-container {
      min-height: 497px;
    }


  .img-container > img {
    max-width: 100%;
  }

  .docs-preview {
    margin-right: -1rem;
  }

  .img-preview {
    float: left;
    margin-bottom: .5rem;
    margin-right: .5rem;
    overflow: hidden;
  }

  .img-preview > img {
    max-width: 100%;
  }

  .preview-lg {
    height: 9rem;
    width: 16rem;
  }

  .preview-md {
    height: 4.5rem;
    width: 8rem;
  }

  .preview-sm {
    height: 2.25rem;
    width: 4rem;
  }

  .preview-xs {
    height: 1.125rem;
    margin-right: 0;
    width: 2rem;
  }

  .docs-data > .input-group {
    margin-bottom: .5rem;
  }

  .docs-data .input-group-prepend .input-group-text {
    min-width: 4rem;
  }

  .docs-data .input-group-append .input-group-text {
    min-width: 3rem;
  } 

  .docs-buttons > .btn,
  .docs-buttons > .btn-group,
  .docs-buttons > .form-control {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .docs-toggles > .btn,
  .docs-toggles > .btn-group,
  .docs-toggles > .dropdown {
    margin-bottom: .5rem;
  }

  .docs-tooltip {
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .docs-tooltip > .icon {
    margin: 0 -.25rem;
    vertical-align: top;
  }

  .tooltip-inner {
    white-space: normal;
  }

  .btn-upload .tooltip-inner,
  .btn-toggle .tooltip-inner {
    white-space: nowrap;
  }

  .btn-toggle {
    padding: .5rem;
  }

  .btn-toggle > .docs-tooltip {
    margin: -.5rem;
    padding: .5rem;
  }


    .btn-group-crop {
      margin-right: 5px !important;
    }

    .btn-group-crop > .btn {
      padding-left: .5rem;
      padding-right: .5rem;
    }

    .btn-group-crop .docs-tooltip {
      margin-left: -.5rem;
      margin-right: -.5rem;
      padding-left: .5rem;
      padding-right: .5rem;
    }


  .docs-options .dropdown-menu {
    width: 100%;
  }

  .docs-options .dropdown-menu > li {
    font-size: .875rem;
    padding: .125rem 1rem;
  }

  .docs-options .dropdown-menu .form-check-label {
    display: block;
  }

  .docs-cropped .modal-body {
    text-align: center;
  }

  .docs-cropped .modal-body > img,
  .docs-cropped .modal-body > canvas {
    max-width: 100%;
  }
}