%cropper-position {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
%cropper-bg {
  background-color: $light-color;
  content: ' ';
  display: block;
  position: absolute;
}
%cropper-position-another {
  display: block;
  height: 100%;
  opacity: .1;
  position: absolute;
  width: 100%;
}
.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  user-select: none;
  img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }
}
.cropper-wrap-box {
  @extend %cropper-position;
  overflow: hidden;
}
.cropper-canvas {
  @extend %cropper-position;
  overflow: hidden;
}
.cropper-drag-box {
  @extend %cropper-position;
  background-color: $white;
  opacity: 0;
}
.cropper-crop-box {
  @extend %cropper-position;
}
.cropper-modal {
  @extend %cropper-position;
  background-color: $black;
  opacity: .5;
}
.cropper-view-box {
  display: block;
  height: 100%;
  outline-color: var(--theme-deafult);
  outline: 1px solid var(--theme-deafult);
  overflow: hidden;
  width: 100%;
}
.cropper-dashed {
  border: 0 dashed $light-color;
  display: block;
  opacity: .5;
  position: absolute;
  &.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: 33.33333%;
    left: 0;
    top: 33.33333%;
    width: 100%;
  }
  &.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: 33.33333%;
    top: 0;
    width: 33.33333%;
  }
}
.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: .75;
  position: absolute;
  top: 50%;
  width: 0;
  &:before {
    @extend %cropper-bg;
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }
  &:after {
    @extend %cropper-bg;
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }
}
.cropper-face {
  @extend %cropper-position-another;
  background-color: $white;
  left: 0;
  top: 0;
}
.cropper-line {
  @extend %cropper-position-another;
  background-color: var(--theme-deafult);
  &.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }
  &.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }
  &.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }
  &.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }
}
.cropper-point {
  @extend %cropper-position-another;
  background-color: var(--theme-deafult);
  height: 5px;
  opacity: .75;
  width: 5px;
  &.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }
  &.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }
  &.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }
  &.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }
  &.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }
  &.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }
  &.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }
  &.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
    &:before {
      background-color: var(--theme-deafult);
      bottom: -50%;
      content: ' ';
      display: block;
      height: 200%;
      opacity: 0;
      position: absolute;
      right: -50%;
      width: 200%;
    }
  }
}

.cropper-invisible {
  opacity: 0;
}
.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.cropper-hidden {
  display: none !important;
}
.cropper-move {
  cursor: move;
}
.cropper-crop {
  cursor: crosshair;
}
.cropper-disabled {
  .cropper-drag-box {
    cursor: not-allowed;
  }
  .cropper-face {
    cursor: not-allowed;
  }
  .cropper-line {
    cursor: not-allowed;
  }
  .cropper-point {
    cursor: not-allowed;
  }
}