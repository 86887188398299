/**=====================
     Dashboard 3 CSS Start
==========================**/
@keyframes hand-move {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  80% {
     transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.welcome-card {
    background-image: url(../images/dashboard-3/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: $white;
    h4 {
        img {
            margin-top: -6px;
            animation: hand-move 5s infinite ease-in;
        }
    }
    p {
        width: 75%;
        @media (max-width:1830px) {
          width: 62%;
        }
        @media (max-width:991px) {
          width: 70%;
        }
        @media (max-width:480px) {
          width: 100%;
        }
    }
    .welcome-img {
        height: 200px;
        position: absolute;
        right: 0;
        bottom: -32px;
        [dir="rtl"] & {
          right: unset;
          left: 0;
        }
        @media (max-width:1780px) {
          height: 180px;
          bottom: -28px;
        }
        @media (max-width:1700px) {
          bottom: -23px;
        }
        @media (max-width:767px) {
          height: 140px;
          bottom: -18px;
        }
        @media (max-width:480px) {
          display: none;
        }
    }
}
.course-box {
    overflow: hidden;
    .card-body {
        padding: 25px 15px;
        @media (max-width: 1722px) {
          padding: 20px 15px;
        }
        @media (max-width: 1660px) {
          padding: 16px 15px;
        }
    }
    &.widget-course {
      .card-body {
        @media (max-width: 1660px) {
          padding: 25px 15px;
        }
      }
    }
    &:hover {
      transform: translateY(-5px);
      transition: 0.5s;
      .course-icon {
        svg {
          animation: tada 1.5s ease infinite;
        }
      }
    }
}
.course-widget {
    display: flex;
    gap: 8px;
    .course-icon {
        position: relative;
        width: 54px;
        height: 54px;
        clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);
        background: linear-gradient(310.31deg, #FF3364 14.71%, #FF7F9E 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 26px;
            height: 26px;
            fill: $white;
        }
        &.warning {
            background: linear-gradient(297.08deg, #FF9E2A -4.53%, #FFA941 98.25%);
        }
    }
    .btn-light {
        background: var(--course-light-btn) !important;
        border: none;
    }
    a {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        margin-top: 25px;
        span {
            display: block;
            margin-bottom: -5px;
        }
        svg {
            width: 13px;
            height: 13px;
        }
    }
}
.get-card {
    .card-header {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.square-group {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
    [dir="rtl"] & {
      right: unset;
      left: 0;
    }
    .square-1 {
        width: 12px;
        height: 18px;
    }
    .square-2 {
        width: 12px;
        height: 10px;
        margin-bottom: -10px;
    }
    .square-3 {
        width: 18px;
        height: 10px;
        margin-left: 10px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 10px;
        }
    }
    .square-4 {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 20px;
        right: -15px;
        [dir="rtl"] & {
          right: unset;
          left: -15px;
        }
    }
    .square-5 {
        width: 6px;
        height: 6px;
        bottom: 22px;
        position: absolute;
        right: -1px;
        [dir="rtl"] & {
          right: unset;
          left: -1px;
        }
    }
    .square-6, .square-7 {
        position: absolute;
        width: 4px;
        height: 4px;
    }
    .square-6 {
        right: -23px;
        bottom: 5px;
        [dir="rtl"] & {
          right: unset;
          left: -23px;
        }
    }
    .square-7 {
        right: 8px;
        bottom: 45px;
        [dir="rtl"] & {
          right: unset;
          left: 8px;
        }
    }
    .warning1 {
        background: #FFD497;
    }
    .warning {
        background: #FFA73B;
    }
    .primary {
        background: $primary-color;
    }
    .danger {
        background: #f54132;
    }
    .light {
        background: #E6E8EF;
    }
    .success {
        background: $success-color;
    }
}
.progress-chart-wrap {
    margin: -40px 0 -22px;
    [dir="rtl"] & {
      direction: ltr;
    }
    @media (max-width: 991px) {
      margin-top: -18px;
    }
    .apexcharts-canvas {
      margin: 0 auto;
        .apexcharts-datalabels-group {
           .apexcharts-datalabel-label {
                fill: var(--chart-text-color);
           }
        }
    }
}
.get-card {
    .btn {
        margin-top: 15px;
        padding: 8px 15px;
        &:hover {
          svg {
            transform: translateX(5px);
            transition: 0.5s;
          }
        }
        svg {
            width: 13px;
            height: 13px;
            fill: $white;
            vertical-align: middle;
            transition: 0.5s;
        }
    }
}
.default-datepicker {
  .datepicker-inline {
    .datepicker {
      width: auto;
      background: $white;
      box-shadow: none;
      padding: 0;

      .datepicker--content {
        padding: 0;
        .datepicker--days {
          .datepicker--days-names {
            margin: 27px 0 0;
            padding: 15px 0;

            .datepicker--day-name {
              color: $dark-editor-document;
              font-size: 14px;
            }
          }

          .datepicker--cells {
            .datepicker--cell-day {
              height: 35px;
              width: 14%;
              color: $dark-editor-document;

              &.-other-month- {
                color: $dark-editor-document;
                opacity: 20%;
              }
              &.-weekend- {
                + .-weekend- {
                    color: $danger-color;
                }
              }
            }

            .datepicker--cell {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              z-index: 0;
              letter-spacing: 2px;

              &.-selected- {
                background: var(--theme-deafult);
                color: $white;
                position: relative;
                box-shadow: 0px 0px 13px 0 rgba($primary-color, 0.5);
              }

              &.-current- {
                background-color: $success-color;
                border: none;
                color: $white;
                &::after {
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border: 2px solid var(--white);
                    background: $danger-color;
                    right: -3px;
                    top: -2px;
                    border-radius: 100%;
                }
              }

              &.-focus- {
                color: $white;
                box-shadow: none;
              }
            }
          }
        }

        .datepicker-cell {
          .datepicker--cell-month {
            &.-current- {
              &.-selected- {
                background-color: var(--theme-deafult);
              }
            }
          }
        }
      }

      .datepicker--nav {
        border-bottom: none;
        padding: 0;
        text-transform: capitalize;
        margin-top: 0;

        .datepicker--nav-action {
          background-color: transparent;
          width: 22px;
          height: 22px;
          &[data-action="prev"] {
            position: absolute;
            right: 50px;
            [dir="rtl"] & {
              right: unset;
              left: 50px;
            }
          }
          svg {
            width: 22px;
            height: 22px;
          }
          path {
            stroke: var(--chart-text-color);
          }
        }
      }

      .datepicker--nav-title {
        color: $dark-editor-document;
        font-size: 20px;
        font-weight: 500;
        @media (max-width:767px) {
          font-size: 14px;
        }

        i {
          margin-left: 10px;
          font-weight: 500;
          font-size: 20px;
          color: $dark-editor-document;
          @media (max-width:767px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.learning-wrap {
    margin: -24px -10px -24px -15px;
}
.activity-wrap {
    margin: -22px 0 -22px -28px;
    [dir="rtl"] & {
      margin: -22px -28px -22px 0;
    }
}
.upcoming-event-wrap {
  margin-bottom: -39px;
  margin-left: -24px;
}
.course-card {
  .dropdown {
    .dropdown-toggle {
      width: 125px;
    }
  }
}
.course-wrapper {
  text-align: center;
  .course-icon-box {
    position: relative;
    padding: 8px;
    @media (max-width:767px) {
      padding: 0;
    }
    .icon-wrap {
      width: 117px;
      height: 92px;
      background-color: var(--course-bg);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin: 0 auto;
      box-shadow: -18px 15px 20px rgba(119, 119, 119, 0.25);
      @media (max-width: 1880px) {
        width: 112px;
      }
      @media (max-width:767px) {
        box-shadow: none;
      }
      @media (max-width:575px) {
        width: 92px;
        height: 82px;
      }
      img {
        width: 80px;
        @media (max-width:575px) {
          width: 70px;
        }
      }
    }
    .arrow-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 20px);
      height: 100%;
      [dir="rtl"] & {
        left: unset;
        right: 0;
        transform: scaleX(-1);
      }
      @media (max-width:767px) {
        display: none;
      }
    }
  }
  h6 {
    margin-top: 25px;
    margin-bottom: 0;
    @media (max-width: 575px) {
      margin-top: 15px;
    }
  }
}
.course-main-card {
  display: flex;
  gap: 20px;
  justify-content: center;
  @media (max-width: 1880px) {
    flex-wrap: wrap;
  }
  @media (max-width:767px) {
    gap: 12px;
    flex-wrap: nowrap;
  }
  @media (max-width: 575px) {
    gap: 20px;
    flex-wrap: wrap;
  }
  .course-wrapper {
    &:last-child {
      .arrow-bg {
        width: 100%;
      }
    }
  }
}
.schedule-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width:767px) {
    flex-direction: row;
  }
  @media (max-width:575px) {
    flex-direction: column;
  }
  li {
    display: flex;
    align-items: center;
    h6 {
      font-size: 14px;
    }
    ul {
      column-count: 2;
      column-rule-style: solid;
      column-rule-color: var(--chart-text-color);
      column-gap: 20px;
      column-rule-width: 1px;
      @media (max-width:1712px) {
        column-gap: 14px;
      }
      @media (max-width: 1199px) {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        gap: 6px;
      }
    }
    img {
      border-radius: 100%;
      width: 37px;
      height: 37px;
      padding: 3px;
    }
    svg {
      width: 15px;
      height: 15px;
      &.f-success {
        fill: $success-color;
      }
    }
    
  }
  > li {
    padding: 15px 20px;
    box-shadow: 4px 7px 17px 5px rgba(46, 35, 94, 0.05);
    gap: 10px;
    position: relative;
    border-radius: 10px;
    @media (max-width:1712px) {
      padding: 16px 14px;
    }
    &::before {
      position: absolute;
      content: '';
      width: 5px;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 15px 0 0 15px;
      [dir="rtl"] & {
        left: unset;
        right: 0;
        border-radius: 0 15px 15px 0;
      }
    }
    &.primary {
      &::before {
         background-color: var(--theme-deafult);
      }
      img {
        border: 1px solid var(--theme-deafult);
      }
    }
    &.warning {
      &::before {
         background-color: $warning-color;
      }
      img {
        border: 1px solid $warning-color;
      }
    }
  }
}
.link-only {
  display: flex;
  align-items: center;
  color: $theme-body-sub-title-color;
  svg {
    width: 16px;
    height: 16px;
  }
}
.lessons-lists {
  li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 0;
    img {
      transition: 0.5s;
    }
    + li {
      border-top: 1px solid var(--chart-dashed-border);
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:hover {
      img {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }
}
.lesson-wrap {
  width: 50px;
  margin-bottom: -11px;
  margin-top: -6px;
  .apexcharts-canvas {
    .apexcharts-datalabel-label {
      display: none;
    }
  }
}

// responsive

@media only screen and (max-width: 575px) {
    .default-datepicker {
        .datepicker-inline {
        .datepicker {
            .datepicker--nav-title {
            font-size: 17px;

            i {
                font-size: 17px;
                margin-left: 10px;
            }
            }

            .datepicker--content {
            .datepicker--days {
                .datepicker--cells {
                .datepicker--cell {
                    font-size: 13px;
                }
                }
            }
            }
        }
        }
    }
}

@media only screen and (max-width: 360px) {
  .default-datepicker {
    .datepicker-inline {
      .datepicker {
        .datepicker--content {
          .datepicker--days {
            .datepicker--cells {
              .datepicker--cell {
                &.-selected- {
                  &:before {
                    height: 36px;
                  }
                }
              }

              .datepicker--cell-day {
                height: 36px;
              }
            }

            .datepicker--days-names {
              margin: 13px 0 13px;

              .datepicker--day-name {
                font-size: 12px;
              }
            }
          }
        }

        .datepicker--nav-title {
          i {
            margin-left: 15px;
            font-size: 25px;
          }
        }
      }
    }
  }
}