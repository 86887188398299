
.profile-box {
  background: linear-gradient(
    to right,
    #29b777 0%,
    26.06635071090048%,
    #278e80 42.65402843601896%,
    74.40869025999912%,
    #26458d 100%
  );

  /* Gradient in Hex */

  /* Gradient in RGBA */
  // linear-gradient(to right, rgba(41, 183, 119, 1) 0%, 24.644549763033176%, rgba(39, 142, 128, 1) 49.28909952606635%, 74.64454976303318%, rgba(38, 69, 141, 1) 100%);
  /* Gradient in Hex */
  /* Gradient in RGBA */
  // linear-gradient(to right, rgba(41, 183, 119, 1) 0%, 26.06635071090048%, rgba(39, 142, 128, 1) 42.65402843601896%, 74.40869025999912%, rgba(38, 69, 141, 1) 100%);
}
